@tailwind base;
@tailwind components;
@tailwind utilities;


.centered{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card{
    transform-style: preserve-3d;
    transition: all 0.8s ease;
  }
  
  .front{
    backface-visibility: hidden;
  }
  
  .back{
    backface-visibility: hidden;
    transform: rotateY(180deg);
  }
  
  .cardFlip{
    transform: rotateY(180deg);
  }